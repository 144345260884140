import * as React from "react"
import * as backendSectionStyles from "./backend-section.module.scss"
import * as interfaceSectionStyles from "./interface-section.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const InterfaceSection = () => (
  <section className={`container ${interfaceSectionStyles.wrapper}`}>
    <div className="row" style={{width: "inherit"}}>
      <div className="col-lg-6 d-flex flex-column justify-content-center"
           data-sal="slide-right"
           data-sal-duration="1000"
           data-sal-delay="250"
           data-sal-easing="ease">
        <header>
          <h2>Dynamic drag-and-drop interface</h2>
        </header>
        <p>
          With the intuitive drag-and-drop UI you can create and edit photonic
          quantum circuits and visualize their results and export them for later use.
        </p>
        <Link to="https://docs.piquasso.com/"  className={backendSectionStyles.learnMore}>Learn more about our interface</Link>
      </div>
      <div className="col-lg-6"
           data-sal="slide-left"
           data-sal-duration="1000"
           data-sal-delay="250"
           data-sal-easing="ease">
        <StaticImage className={interfaceSectionStyles.interfaceImg}
                     src="../../assets/images/interface2.png"
                     alt="Piquasso Interface"
                     layout="fixed"
                     width={1400}/>
      </div>
    </div>
  </section>
)

export default InterfaceSection
