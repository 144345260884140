import * as React from "react"
import { Link } from "gatsby"
import * as backendSectionStyles from "./backend-section.module.scss"
import Animation2 from "../../assets/video/animation2b.mp4"
import {Nav} from "react-bootstrap";

const BackendSection = () => (
  <section className={`container ${backendSectionStyles.wrapper}`}>
    <div className="row">
      <div className="col-lg-6 order-last order-lg-first d-flex">
        <video className={`w-100 ${backendSectionStyles.videoPlayer}`} autoPlay loop muted>
          <source src={Animation2} type="video/mp4" />
        </video>
      </div>
      <div className="col-lg-6 order-first order-lg-last d-flex flex-column justify-content-center"
           data-sal="slide-left"
           data-sal-duration="1000"
           data-sal-delay="250"
           data-sal-easing="ease">
        <header>
          <h2>Fast and reliable backend</h2>
        </header>
        <p>
          The simulator backend uses state-of-the-art algorithms to simulate photonic quantum computations
        </p>
        <Link to="https://docs.piquasso.com/simulators/gaussian.html" className={backendSectionStyles.learnMore}>Learn more about the backends</Link>
      </div>
    </div>
  </section>
)

export default BackendSection
