import * as React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as communitySectionStyles from "./community-section.module.scss"
import * as welcomeSectionStyles from "./welcome-section.module.scss"
import ArrowRight from "../../assets/icons/arrow_right.svg"

const CommunitySection = () => {
  return (
    <section className={`container mt-4 ${communitySectionStyles.wrapper}`}>
      <div className="row">
        <div className={`col-lg-6 d-flex flex-column pt-4 mb-5 ${communitySectionStyles.header}`}
             data-sal="slide-right"
             data-sal-duration="1000"
             data-sal-delay="250"
             data-sal-easing="ease">
          <header>
            <h2>Piquasso Community</h2>
          </header>
          <p className="mb-5">
            Browse, find and discover new circuits in Piquasso’s rapidly growing public repository.
            Use the sharing functionality to extend the community’s knowledge base with your ideas.
          </p>
          <button className={`mr-5 ${welcomeSectionStyles.actionButtonSimulator} ${welcomeSectionStyles.actionButtons} ${communitySectionStyles.actionBtn}`}
                  role="link"
                  onClick={() => {
                    navigate("https://app.piquasso.com/published")
                  }}>
            <div className="d-flex justify-content-between align-items-center">
              Discover
              <div className="d-flex">
                <ArrowRight className="mr-1" style={{opacity: .9, height: "12px", width: "10px"}}/>
                <ArrowRight className="mr-1" style={{opacity: .6, height: "12px", width: "10px"}}/>
                <ArrowRight className="mr-1" style={{opacity: .3, height: "12px", width: "10px"}}/>
              </div>
            </div>
          </button>
        </div>
        <div className={`col-lg-6 ${communitySectionStyles.circuitCol}`}
             data-sal="slide-left"
             data-sal-duration="1000"
             data-sal-delay="250"
             data-sal-easing="ease">
          <div className={`d-flex offset-4 offset-lg-4 mb-3 ${communitySectionStyles.circuitRow}`}>
            <div className={`col-6 col-sm-6 col-md-5 col-lg-4 mr-3 ${communitySectionStyles.circuitCard}`}>
              <h3>Gaussian Boson Sampling</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo1.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@joczikszabi</p>
              </div>
            </div>
            <div className={`col-8 col-sm-8 col-md-6 col-lg-4 mr-3 ${communitySectionStyles.circuitCard}`}>
              <h3>Variational Quantum Eigensolver</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo2.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@lesliesipe</p>
              </div>
            </div>
          </div>
          <div className={`d-flex offset-6 offset-lg-6 mb-3 ${communitySectionStyles.circuitRow}`}>
            <div className={`col-6 col-sm-8 col-md-5 col-lg-4 mr-3 ${communitySectionStyles.circuitCard}`}>
              <h3>Quantum Neural Network</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo3.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@jennywilson</p>
              </div>
            </div>
            <div className={`col-5 col-sm-5 col-lg-4 mr-3 ${communitySectionStyles.circuitCard}`}>
              <h3>Gate teleportation</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo4.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@floydmiles</p>
              </div>
            </div>
            <div className={`col-sm-7 col-lg-4 mr-3 d-none d-sm-block ${communitySectionStyles.circuitCard}`}>
              <h3>Dense subgraph search</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo9.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@benmuller</p>
              </div>
            </div>
          </div>
          <div className={`d-flex mb-3 offset-2 offset-lg-2 ${communitySectionStyles.circuitRow}`}>
            <div className={`col-8 col-sm-7 col-md-6 col-lg-4 mr-3 ${communitySectionStyles.circuitCard}`}>
              <h3>Differential equation solver</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo5.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@robertfox</p>
              </div>
            </div>
            <div className={`col-4 col-sm-7 col-md-5 col-lg-4 mr-3 ${communitySectionStyles.circuitCard}`}>
              <h3>Graph Similarity</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo6.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@liuxang</p>
              </div>
            </div>
            <div className={`col-6 col-sm-8 col-md-5 col-lg-4 mr-3 ${communitySectionStyles.circuitCard}`}>
              <h3>Quantum Neural Network</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo3.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@jennywilson</p>
              </div>
            </div>
          </div>
          <div className={`d-flex mb-3 ${communitySectionStyles.circuitRow}`}>
            <div className={`col-6 col-sm-4 col-lg-4 mr-3 ${communitySectionStyles.circuitCard}`}>
              <h3>Poisson equation</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo7.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@wangli</p>
              </div>
            </div>
            <div className={`col-5 col-sm-7 col-md-4 col-lg-4 mr-3 ${communitySectionStyles.circuitCard}`}>
              <h3>Point processes</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo8.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@zhangwei</p>
              </div>
            </div>
            <div className={`col-7 col-sm-7 col-md-4 col-lg-4 mr-3 ${communitySectionStyles.circuitCard}`}>
              <h3>Dense subgraph search</h3>
              <div className="d-flex flex-row align-items-center">
                <StaticImage className="mr-2"
                             src="../../assets/photos/photo9.png"
                             alt="Profile picture"
                             quality={100}
                             width={25} />
                <p>@benmuller</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CommunitySection

