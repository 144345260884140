import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import WelcomeSection from "../components/home/welcomeSection"
import BackendSection from "../components/home/backendSection"
import InterfaceSection from "../components/home/interfaceSection"
import CollaborateSection from "../components/home/collaborateSection"
import CommunitySection from "../components/home/communitySection"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <WelcomeSection />
    <BackendSection />
    <InterfaceSection />
    <CommunitySection />
  </Layout>
)

export default IndexPage
