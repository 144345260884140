import * as React from "react"
import * as welcomeSectionStyles from "./welcome-section.module.scss"
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDiscord, faGithub } from "@fortawesome/free-brands-svg-icons"
import Lightbeams from "../../assets/video/beam1.mp4"
import {Nav} from "react-bootstrap";


const WelcomeSection = () => (
  <section className={`container ${welcomeSectionStyles.wrapper}`}>
    <div className="row">
      <div className="col-lg-12">
        <h1>Fast simulator of Photonic Quantum Computers</h1>
      </div>
      <div className="col-sm-12 col-lg-10 offset-lg-1 mb-2">
        <p>A Python / C++ framework for efficient photonic quantum computer simulation.</p>
      </div>
      <div className={`col-lg-12 mt-5 ${welcomeSectionStyles.actionButtonLayout}`}>
        <button className={`${welcomeSectionStyles.actionButtonSimulator} ${welcomeSectionStyles.actionButtons}`} role="link" onClick={()=>{ navigate("https://app.piquasso.com/") }}>Simulator</button>
        <button className={`${welcomeSectionStyles.actionButtonDocumentation} ${welcomeSectionStyles.actionButtons}`} role="link" onClick={()=>{ navigate("https://docs.piquasso.com/") }}>Documentation</button>
      </div>
      <div className={`col-lg-12 mt-5 d-flex flex-row align-items-center justify-content-center ${welcomeSectionStyles.findUsWrapper}`}>
        <p className="mr-3">Find us on</p>
        <a href="https://github.com/Budapest-Quantum-Computing-Group" target="_blank" className="mr-3">
          <FontAwesomeIcon className={welcomeSectionStyles.githubBtn} icon={ faGithub } size="2x" />
        </a>
        <p className="mr-3">or</p>
        <a href="https://discord.gg/c4xmF5PPaj" target="_blank">
          <FontAwesomeIcon className={welcomeSectionStyles.discordBtn} icon={ faDiscord } size="2x" />
        </a>
      </div>
    </div>
    <video className={welcomeSectionStyles.videoPlayer} autoPlay loop muted>
      <source src={Lightbeams} type="video/mp4" />
    </video>
  </section>
)

export default WelcomeSection
